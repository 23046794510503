
export enum ItemCategory {
  Experience,
  Stats,
  Item
}

export default class Item {
  id: string;
  name: string;
  category: ItemCategory;
  amount = 0;

  constructor(
    name: string,
    category: ItemCategory,
    amount?: number,
  ) {
    this.id = this.guidGenerator()
    this.name = name;
    this.category = category;
    this.amount = amount || 1;
  }

  guidGenerator() {
    const S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  copyWith({id, name, category, amount}: {
    id?: string | undefined,
    name?: string | undefined,
    category?: ItemCategory | undefined,
    amount?: number | undefined
  }): Item {
    if (id !== undefined) {
      this.id = id
    }

    if (name !== undefined) {
      this.name = name;
    }

    if (category !== undefined) {
      this.category = category;
    }

    if (amount !== undefined) {
      this.amount = amount;
    }

    return this;
  }

  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      name: this.name,
      category: this.category,
      amount: this.amount,
    })
  }

  toWikiTag(): string {
    let result = '{{'

    switch (this.category) {
      case ItemCategory.Experience: 
        result += `EXP|${this.name}|${this.amount}` 
        break;
      case ItemCategory.Stats:
        result += `Stats|${this.name}|+${this.amount} Bonus|${this.name}` 
        break;
      case ItemCategory.Item:
        result += `il|${this.name}` 
        if (this.amount > 1) {
          result += `|${this.amount}` 
        }
        break;
    }

    result += '}}'

    return result
  }

  static fromJSON(json: string): Item {
    const parsed = JSON.parse(json)

    const item = new Item(
      parsed.name,
      parsed.category,
      parsed.amount,
    )

    return item.copyWith({id: parsed.id})
  }
}

