
export default class Dialogue {
  id: string;
  speaker: string | null;
  line: string;
  race: string | null;
  indent = 0;
  ignored = false;
  heartChange = 0;

  constructor(
    speaker: string,
    line: string,
    indent?: number,
    ignored?: boolean,
    race ?: string,
    heartChange?: number
  ) {
    this.id = this.guidGenerator()
    this.speaker = speaker;
    this.line = line;
    this.indent = indent || 0;
    this.ignored = ignored || false;
    this.race = race || null;
    this.heartChange = heartChange || 0;
  }

  guidGenerator() {
    const S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  get cleanLine(): string {
    return this.line
        .replaceAll(/XX/g, "{{PLAYER}}")
        .replaceAll(/\[\]/g, "<br>")
        .replaceAll(/\[Player\]/g, "{{PLAYER}}")
        .replaceAll(RegExp('<color=#[0-9A-Z]{6}>', "g"), "")
        .replaceAll(RegExp('</color>', "g"), "")
        .replaceAll(RegExp('<i>', "g"), this.isAction ? "" : "''")
        .replaceAll(RegExp('</i>', "g"), this.isAction ? "" : "''")
        .replaceAll(RegExp('> ', "g"), "")
    ;  
  }

  get isAction(): boolean {
    return this.speaker === '' || this.speaker === undefined || this.speaker === null;
  }

  copyWith({id, speaker, indent, ignored, race, heartChange}: {
    id?: string | undefined,
    speaker?: string | undefined,
    line?: string | undefined,
    indent?: number | undefined,
    ignored?: boolean | undefined,
    race?: string | undefined,
    heartChange?: number | undefined,
  }): Dialogue {
    if (id !== undefined) {
      this.id = id
    }

    if (speaker !== undefined) {
      this.speaker = speaker;
    }

    if (indent !== undefined) {
      this.indent = indent;
    }

    if (ignored !== undefined) {
      this.ignored = ignored;
    }

    if (race !== undefined) {
      this.race = race;
    }

    if (heartChange !== undefined) {
      this.heartChange = heartChange;
    }

    return this;
  }

  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      speaker: this.speaker,
      line: this.line,
      race: this.race,
      indent: this.indent,
      ignored: this.ignored,
      heartChange: this.heartChange
    })
  }

  static fromJSON(json: string): Dialogue {
    const parsed = JSON.parse(json)

    const dialogue = new Dialogue(
      parsed.speaker,
      parsed.line,
      parsed.indent,
      parsed.ignored,
      parsed.race,
      parsed.heartChange
    )

    return dialogue.copyWith({id: parsed.id})
  }

  static get HeartChangeRegex(): RegExp {
    return new RegExp(/(\+|-)[1-9]* {{il\|Heart\|icon\|size=[0-9]+px}} Points/, "g")
  }

  static get DialogueDescriptionRegex(): RegExp {
    return new RegExp(/\|(Dialogue|Option[1-9]+[a-z]*|Response[1-9]+[a-z]*)=/, "g")
  }
}

