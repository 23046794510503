import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: 'h-8 rounded-md px-2 py-3 flex flex-row justify-center ' +
    'bg-purple-400 dark:bg-purple-900 ' +
    'hover:bg-gradient-to-b hover:to-purple-400 hover:from-pink-400 ' + 
    'hover:dark:bg-gradient-to-b hover:dark:to-purple-900 hover:dark:from-pink-900 ' +
    'hover:drop-shadow-md ' +
    'text-slate-900 dark:text-slate-200 ' +
    'flex flex-row items-center content-center gap-2 ' + $props.addClasses
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event.target.value)))
  }), [
    ($props.text !== undefined)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.text), 1))
      : _createCommentVNode("", true),
    ($props.icon !== undefined)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: 'fa-solid ' + $props.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 16))
}