import { InjectionKey } from 'vue'
import { Commit, GetterTree, Store, createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { npcImages } from './npc_images'
import Dialogue from '@/models/dialogue'
import { cutscene } from './cutscene_module'
import { questReward } from './quest_reward_module'
import Item from '@/models/item'
import { dropsModule } from './drops_module'
import Drop from '@/models/drop'
import { fishSpawnerModule } from './fish_spawner_module'
import FishSpawn from '@/models/fish_spawn'

export interface RootState {
  npcs: string[],
  races: string[],
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export interface CommitFunction {
  commit: Commit;
}

export interface CommitStateFunction<T> extends CommitFunction {
  state: T;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ['cutscene', 'questReward', 'dropsModule', 'fishSpawnerModule'],
  restoreState:(key: any, storage: any): any => {
    
    try {
      const storeObject = JSON.parse(storage[key])
      const dialogues = storeObject.cutscene.dialogues.map((item: string) => Dialogue.fromJSON(item))
      
      storeObject.cutscene.dialogues = dialogues

      const rewards = storeObject.questReward.rewards.map((item: string) => Item.fromJSON(item))
      storeObject.questReward.rewards = rewards
      
      const bonusRewards = storeObject.questReward.bonusRewards.map((item: string) => Item.fromJSON(item))
      storeObject.questReward.bonusRewards = bonusRewards

      const drops = storeObject.dropsModule.drops.map((item: string) => Drop.prototype.fromJSON(item))
      storeObject.dropsModule.drops = drops

      const fishSpawns = storeObject.fishSpawnerModule.spawns.map((item: string) => FishSpawn.prototype.fromJSON(item))
      storeObject.fishSpawnerModule.spawns = fishSpawns
  
      return storeObject
    } catch {
      return {
        cutscene: {
          editingText: '',
          dialogues: [] as Dialogue[],
          title: '',
          collapse: 'Blank',
          size: 'Medium'
        },
        questReward: {
          editingText: '',
          rewards: [] as Item[],
          bonusRewards: [] as Item[]
        },
        drops: {
          editingText: '',
          drops: [] as Drop[]
        }
      }
    }
  },
})

export const store = createStore({
  state() { 
    return {
      npcs: [
        '',
        'REPLACE_ME',
        'Player',
        'Albert',
        'Alfie',
        'Allison',
        'Altar Keeper',
        'Amanda',
        'Angel',
        'Anne',
        'Arianella',
        'Arnold',
        'Arvel',
        'Barry',
        'Batilda',
        'Batrick',
        'Beeanca',
        'Benny',
        'Benson',
        'Bernard',
        'Billie',
        'Bloberta',
        'Blossom',
        'Calvin',
        'Camila',
        'Carson',
        'Caspera',
        'Caspian',
        'Cassia',
        'Catherine',
        'Cayde',
        'Charon',
        'Chauncey',
        'Chester',
        'Christine',
        'Claude',
        'Clay',
        'Clemmett',
        'Clive\'s Taxi Service',
        'Coral',
        'Cordelia',
        'Covington',
        'Cynthia',
        'Darius',
        'Darwin',
        'Demon King Minos',
        'Denzel',
        'Dew',
        'Donovan',
        'Dr. Hopsan Barley',
        'Dugan',
        'Duke',
        'Dwayne',
        'Dynus',
        'Edna',
        'Edwin',
        'Elios',
        'Elizabeth',
        'Emma',
        'Emmett',
        'Erica',
        'Eris Umbra',
        'Ettie',
        'Eve',
        'Faeyon',
        'Felicity',
        'Felix',
        'Fidget',
        'Flemmett',
        'Fonzo',
        'Gerald',
        'Giuseppe',
        'Gorwin',
        'Granny',
        'Gren',
        'Griffon',
        'Guard(Sun Haven)',
        'Harris',
        'Head Chef Azarios',
        'Heather',
        'Honey (NPC)',
        'Hungry Slime',
        'Iris',
        'Jade',
        'Jarrod',
        'Jet',
        'Jordan',
        'Judith',
        'Jun',
        'Jun\'s Student',
        'Kai',
        'Kara',
        'Kelsey',
        'Kimi',
        'Kitty',
        'Krusty',
        'Lars',
        'Lester',
        'Lewis',
        'Liam',
        'Lily (NPC)',
        'Linus',
        'Lucia',
        'Lucius',
        'Luis',
        'Luke',
        'Lynn',
        'Lynn\'s Mom',
        'Major Hera',
        'Malachai',
        'Mari',
        'Molly',
        'Morgan',
        'Morpha',
        'Nathaniel',
        'Nim',
        'Nivara',
        'Opal',
        'Otis',
        'PAM',
        'Patrice',
        'Peter',
        'Phoebe',
        'Pinto',
        'Pod',
        'Poppy',
        'Quincy',
        'Raimi',
        'Rastus',
        'Reed',
        'Rel\'Tar',
        'Renee',
        'Rex',
        'Romana',
        'Ronald',
        'Roza',
        'Rufus',
        'Rupert',
        'Ryder',
        'Sable',
        'Salix',
        'Sasha',
        'Scott',
        'Scuzzy',
        'Sierra',
        'Silas',
        'Slate',
        'Slobert',
        'Solon',
        'Sombasu',
        'Sophie',
        'Spectral Knight',
        'Stephen',
        'Sylvia',
        'Sylvius',
        'Tali',
        'Ticket Monster',
        'Tony',
        'Tonya',
        'Topi',
        'Tornn',
        'Train Dragon',
        'Triberius',
        'Ur',
        'Vaan',
        'Vitas Varnas',
        'Vivi',
        'Weedil',
        'Wesley',
        'Willow',
        'Wilt',
        'Wornhardt',
        'Wyatt',
        'Xyla',
        'Zeke',
        'Zenos',
        'Zuri'
      ],
      races: [
        '', 'Angel', 'Amari', 'Demon', 'Human', 'Naga', 'Elementals'
      ],
    }
  },
  getters: {
    npcImageSource: (state: RootState) => (npcName: string) => {
      try {
        if (npcName === 'Player') {
          return 'https://static.wikia.nocookie.net/sun-haven/images/7/7d/Apple.png'
        }
        return npcImages.get(npcName);
      } catch {
        return undefined;
      }
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cutscene,
    questReward,
    dropsModule,
    fishSpawnerModule
  },
  plugins: [vuexLocal.plugin]
})
