import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import './index.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { 
  faUserSecret,
  faArrowUp,
  faArrowDown,
  faChevronDown,
  faChevronUp, 
  faCopy,
  faIndent,
  faChevronLeft,
  faTrash,
  } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
    faUserSecret, 
    faArrowUp, 
    faArrowDown,
    faChevronDown,
    faChevronUp,
    faCopy,
    faIndent,
    faChevronLeft,
    faTrash
  )

createApp(App)
  .use(store, key)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
