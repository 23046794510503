import Drop from "./drop";
import Item from "./item";
import WikiHelperModel from "./wiki_helper_model";

export default class Enemy implements WikiHelperModel<Enemy> {
  id: string;
  name: string;
  drops: Drop[] = [];

  level: number;
  health: number;
  exp: number;

  constructor(
    name: string,
    drops: Drop[],
    level: number,
    health: number,
    exp: number
  ) {
    this.id = name; // TODO: change this
    this.name = name;
    this.drops = drops;
    this.level = level;
    this.health = health;
    this.exp = exp;
  }

  toJSON(): string {
    throw new Error("Method not implemented.");
  }
  toWikiTag(): string {
    throw new Error("Method not implemented.");
  }
  fromJSON(json: string): Enemy {
    throw new Error("Method not implemented.");
  }
  copyWith({id, name, level, health, exp}: {
    id?: string | undefined,
    name?: string | undefined,
    item?: Item | undefined,
    level?: number | undefined,
    health?: number | undefined,
    exp?: number | undefined
  }): Enemy {
    if (id !== undefined) {
      this.id = id
    }

    if (level !== undefined) {
      this.level = level;
    }

    if (health !== undefined) {
      this.health = health;
    }

    if (exp !== undefined) {
      this.exp = exp;
    }

    return this;
  }
}
