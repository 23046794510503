
import { defineComponent } from 'vue';
import ContentContainer from '../components/common/ContentContainer.vue'


export default defineComponent({
  name: 'HomeView',
  components: {
    ContentContainer
  },
});
