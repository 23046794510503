
import { mapGetters, useStore } from 'vuex'
import { key } from '../store';
import { defineComponent } from 'vue'
import ContentContainer from '../components/common/ContentContainer.vue'
import WikiHelperButton from '../components/common/WikiHelperButton.vue'
import LabeledItem from '../components/common/LabeledItem.vue'
import { CutsceneActions } from '@/store/cutscene_module';
import { QuestRewardActions } from '@/store/quest_reward_module';

interface QuestRewardSettings {
  notifyCopied: boolean,
}

export default defineComponent({
  components: { 
    ContentContainer,
    WikiHelperButton, 
    LabeledItem 
  },
  data(): QuestRewardSettings {
    return {
      notifyCopied: false,
    }
  },
  setup () {
    const store = useStore(key)

    return {
      textInput: (store.state as any).questReward.editingText,
      rewards: (store.state as any).questReward.rewards,
      bonusRewards: (store.state as any).questReward.bonusRewards,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    refreshLines() {
      this.$store.commit(QuestRewardActions.SetEditingText.toString(), this.textInput)
      this.$store.dispatch(QuestRewardActions.ParseInput.toString())
    },
    copyOutput (whatToCopy: string) {
      if (whatToCopy === "rewardTable") {
        navigator.clipboard.writeText(this.rewardTable)
      } else if (whatToCopy === "rewardSummary") {
        navigator.clipboard.writeText(this.rewardSummary)
      } else if (whatToCopy === "bonusRewardSummary") {
        navigator.clipboard.writeText(this.bonusRewardSummary)
      }
      this.notifyCopied = true;
      window.setTimeout(() => {
        this.notifyCopied = false;
      }, 4000)
    },
    reset() {
      this.$store.dispatch(CutsceneActions.Reset.toString());
      this.textInput = '';
      (this.$refs.rewardText as HTMLTextAreaElement).value = '';
    }
  },
  computed: {
    ...mapGetters('questReward', {
      rewardTable: 'rewardTable',
      rewardSummary: 'rewardSummary',
      bonusRewardSummary: 'bonusSummary',
    }),
  }
})
