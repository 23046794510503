import Dialogue from "@/models/dialogue"
import { CommitFunction, RootState } from "."
import { Commit } from "vuex"
import Item, { ItemCategory } from "@/models/item"
import Drop, { AmountRange } from "@/models/drop"
import Enemy from "@/models/enemy"

export interface DropsModuleState {
  editingText: string,
  drops: Drop[],
  enemy: Enemy | undefined
}

export enum DropsActions {
  SetEditingText = "dropsModule/setEditingText",
  SetDrops = "dropsModule/setDrops",
  SetEnemy = "dropsModule/setEnemy",
  Reset = "dropsModule/reset",
  ParseInput = "dropsModule/parseRawText",
}

export const dropsModule = {
  namespaced: true,
  state: (): DropsModuleState => ({
    editingText: '',
    drops: [] as Drop[],
    enemy: undefined,
  }),
  actions: {
    reset({ commit }: { commit: Commit }) {
      commit('setEditingText', "")
      commit('setDrops', [])
      commit('setEnemy', undefined)
    },
    /**
     * This handles the following format:
     * 
     * :Details
     * - Level: 24.0
     * - Health: 210.0
     * - Exp: 38.0
     * :Drops
     * - 1 Dragonfly @ 5.0%
     * - 2 Dragonfly @ 3.0%
     * - 45-60 Coins @ 4.0%
     * - 1 Dorado @ 5.0%
     * - 1 Iron Legs @ 10.0%
     * - 1 Iron Helmet @ 10.0%
     * - 1 Iron Chest Plate @ 10.0%
     * - 1 Iron Gloves @ 10.0%
     **/
    parseRawText({ commit, state, rootState }: { commit: Commit, state: DropsModuleState, rootState: RootState }) {
      const tmp = state.editingText.split("\n").filter((line: string) => line.trim() != "")
      const parsedDrops = []
      let parsedEnemy = undefined as Enemy | undefined

      enum ParseState {
        Unknown,
        Drops,
        Details,
      }

      let parseState = ParseState.Unknown
      let i = 0

      while (i < tmp.length) {
        let line = tmp[i]

        // Check if we are on a declaration line
        if (line === ":Drops" && i < tmp.length) {
          parseState = ParseState.Drops
          i++
          line = tmp[i]
        } else if (line === ":Details" && i < tmp.length) {
          parseState = ParseState.Details
          i++
          line = tmp[i]
        } else if (line.startsWith(":") || !line.startsWith("-")) {
          parseState = ParseState.Unknown
        }

        // We are currently parsing underneath :Details
        if (parseState === ParseState.Details) {
          // Wrapping this in a try because this is brittle and heavily reliant on predictable input
          try {
            const tmpEnemy = new Enemy("Enemy", [], 0, 0, 0)
            
            while (tmp[i].includes("Level") || tmp[i].includes("Health") || tmp[i].includes("Exp")) {
              // Pieces will probably look like ["-", "Level:", "10.0"]
              const pieces = tmp[i].split(" ") as string[]

              if (pieces[1].includes("Level")) {
                tmpEnemy.level = Number.parseFloat(pieces[2])
              } else if (pieces[1].includes("Health")) {
                tmpEnemy.health = Number.parseFloat(pieces[2])
              } else if (pieces[1].includes("Exp")) {
                tmpEnemy.exp = Number.parseFloat(pieces[2])
              }

              i++
            }

            // The above while loop causes us to go 1 line too far.
            i--

            if (tmpEnemy.health !== 0 && tmpEnemy.exp !== 0 && tmpEnemy.level !== 0) {
              parsedEnemy = tmpEnemy
            }
          } catch {
            // pass
          }
        }
        // We are currently parsing underneath :Drops
        else if (parseState === ParseState.Drops) {
          // Wrapping this in a try because this is brittle and heavily reliant on predictable input
          try {
            // Pieces will probably look like ["-", "2-3", "Antler", "@", "1.0%", "=>", "20.5%"]
            const pieces = line.split(RegExp(/\s/g)).filter((i) => i != "") as string[]
  
            let amount = { defaultOrLow: 0, high: undefined } as AmountRange
            if (pieces[1].includes("-")) {
              const range = pieces[1].trim().split("-")
              amount = { defaultOrLow: Number.parseInt(range[0]), high: Number.parseInt(range[1]) }
            } else {
              amount = { defaultOrLow: Number.parseInt(pieces[1].trim()), high: undefined }
            }

            const endNameIndex = pieces.findIndex((piece) => piece === "@")

            const percentChance = Number.parseFloat(pieces[pieces.length - 1].trim().replace("%", ""))
  
            console.log(pieces[pieces.length - 3].trim().replace("%", ""))
            parsedDrops.push(new Drop(
              Number.parseFloat(pieces[pieces.length - 3].trim().replace("%", "")),
              pieces.slice(2, endNameIndex).join(" ").trim(),
              amount,
              percentChance
            ))
          } catch {
            // pass
          }
        }

        i++
      }

      commit('setDrops', parsedDrops)
      commit('setEnemy', parsedEnemy)

      const dropSum = parsedDrops.map((drop) => drop.weight).reduce((prev, curr) => prev + curr, 0)
      commit('setDropTotalWeight', dropSum)
    }
  },
  mutations: {
    setEditingText (state: DropsModuleState, payload: string) {
      state.editingText = payload
    },
    setDrops (state: DropsModuleState, payload: Drop[]) {
      state.drops = payload
    },
    setEnemy (state: DropsModuleState, payload: Enemy | undefined) {
      state.enemy = payload
    },
  },
  getters: {
    hasEnemy (state: DropsModuleState): boolean {
      return state.enemy !== undefined
    },
    enemyDropsTable (state: DropsModuleState, getters: any): string {
      let result = []
      
      result.push(`|-| Level ${state.enemy?.level} =`)
      result.push(`{{IfMobile|  '''Level ${state.enemy?.level}'''<br> }}`)
      result.push('TODO: INSERT DESCRIPTION HERE')
      result.push('{| class="wikitable" style="text-align:center;"')
      
      result = result.concat(getters.enemyTableBody)

      result = result.concat(getters.dropsTableBody)

      result.push('|}')

      return result.join("\n")
    },
    dropsTable (state: DropsModuleState, getters: any): string {
      let result = []

      result.push('{| class="wikitable" style="text-align:center;"')
      
      result = result.concat(getters.dropsTableBody)

      result.push("|}")

      return result.join("\n")
    },
    enemyTableBody (state: DropsModuleState): string[] {
      const result = []

      result.push('! colspan=\'2\'|Health !! EXP Granted')
      result.push('|-')
      result.push(`| colspan='2'|{{Stats|Health|${state.enemy?.health}}} || {{EXP|Combat|${state.enemy?.exp}}}`)
      result.push('|-')

      return result
    },
    dropsTableBody (state: DropsModuleState): string[] {
      const result = []

      result.push('! Item !! Amount !! Chance')
      
      for (const drop of state.drops) {
        let amount = drop.amountRange.defaultOrLow.toString()
        if (drop.amountRange.high !== undefined) {
          amount = `${drop.amountRange.defaultOrLow} - ${drop.amountRange.high}` 
        }

        result.push('|-')

        result.push(`| {{i|${drop.itemName}}} || ${amount} || ${drop.percentChance?.toFixed(2)}%`)
      }

      return result
    }
  }
}

