
import Vue, { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      routes: this.$router.getRoutes().reverse()
    }
  }
});
