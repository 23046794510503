import Dialogue from "@/models/dialogue"
import { CommitFunction, RootState } from "."
import { Commit } from "vuex"
import Item, { ItemCategory } from "@/models/item"

export interface QuestRewardModuleState {
  editingText: string,
  rewards: Item[]
  bonusRewards: Item[]
}

export enum QuestRewardActions {
  SetEditingText = "questReward/setEditingText",
  SetRewards = "questReward/setRewards",
  SetBonusRewards = "questReward/setBonusRewards",
  Reset = "questReward/reset",
  ParseInput = "questReward/parseRawText",
}

export const questReward = {
  namespaced: true,
  state: (): QuestRewardModuleState => ({
    editingText: '',
    rewards: [] as Item[],
    bonusRewards: [] as Item[],
  }),
  actions: {
    reset({ commit }: { commit: Commit }) {
      commit('setEditingText', "")
      commit('setRewards', [])
      commit('setBonusRewards', [])
    },
    /**
     *  This handles the following format:
     * 
     * :Rewards:
     * - 80x Combat EXP
     * :Choices:
     * - 1x Iron Pickaxe
     * - 1x Iron Axe
     * - 1x Iron Hoe
     * - 1x Iron Sword
     **/
    parseRawText({ commit, state, rootState }: { commit: Commit, state: QuestRewardModuleState, rootState: RootState }) {
      const tmp = state.editingText.split("\n").filter((line: string) => line.trim() != "")
      const parsedRewards = []
      const parsedChoices = []

      enum ParseState {
        Unknown,
        Rewards,
        Choices
      }

      let parseState = ParseState.Unknown
      let i = 0

      while (i < tmp.length) {
        let line = tmp[i]

        // Check if we are on a declaration line
        if (line === ":Rewards:" && i < tmp.length) {
          parseState = ParseState.Rewards
          i++
          line = tmp[i]
        } else if (line === ":Choices:" && i < tmp.length) {
          parseState = ParseState.Choices
          i++
          line = tmp[i]
        } else if (line.startsWith(":") || !line.startsWith("-")) {
          parseState = ParseState.Unknown
        }

        // We are currently parsing underneath :Rewards:
        if (parseState === ParseState.Rewards) {
          // Wrapping this in a try because it is brittle and heavily reliant on predictable input
          try {
            const pieces = line.split(" ") as string[]
            const amount = Number.parseInt(pieces[1].replace("x", ""))
            if (line.includes("EXP")) {
              parsedRewards.push(new Item(pieces[2], ItemCategory.Experience, amount))
            } else if (line.includes("Bonus")) {
              parsedRewards.push(new Item(pieces[3], ItemCategory.Stats, amount))
            } else {
              parsedRewards.push(new Item(pieces.slice(2, pieces.length).join(" "), ItemCategory.Item, amount))
            }
          } catch {
            // pass
          }
        } else if (parseState === ParseState.Choices) {  // We are currently parsing underneath :Choices:
          // Wrapping this in a try because it is brittle and heavily reliant on predictable input
          try { 
            const pieces = line.split(" ") as string[]
            const amount = Number.parseInt(pieces[1].replace("x", ""))
            if (line.includes("EXP")) {
              parsedChoices.push(new Item(pieces[2], ItemCategory.Experience, amount))
            } else if (line.includes("Bonus")) {
              parsedChoices.push(new Item(pieces[3], ItemCategory.Stats, amount))
            } else {
              parsedChoices.push(new Item(pieces.slice(2, pieces.length).join(" "), ItemCategory.Item, amount))
            }
          } catch {
            // pass
          }
        }

        i++
      }

      commit('setRewards', parsedRewards)
      commit('setBonusRewards', parsedChoices)
    }
  },
  mutations: {
    setEditingText (state: QuestRewardModuleState, payload: string) {
      state.editingText = payload
    },
    setRewards (state: QuestRewardModuleState, payload: Item[]) {
      state.rewards = payload
    },
    setBonusRewards (state: QuestRewardModuleState, payload: Item[]) {
      state.bonusRewards = payload
    },
    setReward (state: QuestRewardModuleState, payload: Item) {
      const index = state.rewards.findIndex((item: Item) => item.id === payload.id);
      state.rewards[index] = payload
    },
    setBonusReward (state: QuestRewardModuleState, payload: Item) {
      const index = state.bonusRewards.findIndex((item: Item) => item.id === payload.id);
      state.bonusRewards[index] = payload
    },
  },
  getters: {
    rewardTable (state: QuestRewardModuleState): string {
      const result = []

      result.push('{| class="article-table tablemedium"')
      result.push('! colspan="2" style="text-align: center;" |Rewards')
      result.push('|-')

      if (state.rewards.length > 0) {
        result.push('! style="width:20%" |Rewards')
        result.push('|' + state.rewards.map((item) => item.toWikiTag()).join('<br>'))
      }

      if (state.bonusRewards.length > 0) {
        result.push('|-')
        result.push('!Bonus Rewards:<br><small>(Choose 1)</small>')
        result.push('|' + state.bonusRewards.map((item) => item.toWikiTag()).join('<br>'))
      }

      result.push("|}")

      return result.join("\n")
    },
    rewardSummary (state: QuestRewardModuleState): string {
      return `|reward1= NAME:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${state.rewards.map((item) => item.toWikiTag()).join("<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp")}`
    },
    bonusSummary (state: QuestRewardModuleState): string {
      return `|bonus1= NAME:<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${state.bonusRewards.map((item) => item.toWikiTag()).join("<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp")}`
    }
  }
}

