<template>
  <div class="min-h-screen h-full bg-slate-300 dark:bg-slate-800 text-slate-900 dark:text-slate-400">
    <div class="mx-auto">
      <slot>

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content-container'

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, html {
  font-family: 'Rubik', sans-serif;
}

pre {
  font-family: 'MartianMono', monospace;
}
</style>