import Item from "./item";
import WikiHelperModel from "./wiki_helper_model";

export interface AmountRange {
  defaultOrLow: number;
  high: number | undefined;
}

export default class Drop implements WikiHelperModel<Drop> {
  id: string;
  weight: number;
  itemName: string;
  amountRange: AmountRange;
  percentChance: number;

  constructor(
    weight: number,
    itemName: string,
    amountRange: AmountRange,
    percentChance: number,
  ) {
    this.id = this.guidGenerator()
    this.weight = weight;
    this.itemName = itemName;
    this.amountRange = amountRange;
    this.percentChance = percentChance;
  }

  guidGenerator() {
    const S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  copyWith({id, weight, itemName, amountRange, percentChance}: {
    id?: string | undefined,
    weight?: number | undefined,
    itemName?: string | undefined,
    amountRange?: AmountRange | undefined,
    percentChance?: number | undefined
  }): Drop {
    if (id !== undefined) {
      this.id = id
    }

    if (weight !== undefined) {
      this.weight = weight;
    }

    if (itemName !== undefined) {
      this.itemName = itemName;
    }

    if (amountRange !== undefined) {
      this.amountRange = amountRange;
    }

    if (percentChance !== undefined) {
      this.percentChance = percentChance;
    }

    return this;
  }

  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      weight: this.weight,
      itemName: this.itemName,
      amountRange: JSON.stringify(this.amountRange),
      percentChance: this.percentChance
    })
  }

  toWikiTag(): string {
    throw Error("toWikiTag is not implemented")
  }

  fromJSON(json: string): Drop {
    const parsed = JSON.parse(json)

    const drop = new Drop(
      parsed.weight,
      parsed.itemName,
      JSON.parse(parsed.amountRange),
      parsed.percentChance
    )

    return drop.copyWith({
      id: parsed.id,
    })
  }
}
