<template>
  <div :class="'flex flex-col justify-start ' + addClasses">
    <label class="text-xs text-slate-600 dark:text-slate-500 text-start self-start">{{labelText}}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    labelText: String,
    addClasses: String
  }
}
</script>

<style>

</style>