
import { load } from 'cheerio';
import axios from 'axios';
import { useStore } from 'vuex';
import { key } from '../../store'
import { computed } from 'vue';


export default {
  props: {
    npcName: String,
  },
  setup(props: { npcName: string }) {
    const store = useStore(key)
  
    let imageSource = computed(() => store.getters.npcImageSource(props.npcName));

    return { npcImageSource: imageSource };
  }
}
