import Item from "./item";
import WikiHelperModel from "./wiki_helper_model";

export default class FishSpawn implements WikiHelperModel<FishSpawn> {
  id: string;
  fishName: string;
  weight: number;
  minPercent: number;
  maxPercent: number;
  season: Season | undefined;

  constructor(
    fishName: string,
    weight: number,
    minPercent: number,
    maxPercent: number,
    season: Season | undefined
  ) {
    this.id = this.guidGenerator()
    this.weight = weight;
    this.fishName = fishName;
    this.minPercent = minPercent;
    this.maxPercent = maxPercent;
    this.season = season;
  }

  guidGenerator() {
    const S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
  }

  copyWith({id, weight, fishName, minPercent, maxPercent, season}: {
    id?: string | undefined,
    weight?: number | undefined,
    fishName?: string | undefined,
    minPercent?: number | undefined,
    maxPercent?: number | undefined,
    season?: Season | undefined,
  }): FishSpawn {
    if (id !== undefined) {
      this.id = id
    }

    if (weight !== undefined) {
      this.weight = weight;
    }

    if (fishName !== undefined) {
      this.fishName = fishName;
    }
    
    if (minPercent !== undefined) {
      this.minPercent = minPercent;
    }
    
    if (maxPercent !== undefined) {
      this.maxPercent = maxPercent;
    }

    if (season !== undefined) {
      this.season = season;
    }

    return this;
  }

  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      weight: this.weight,
      fishName: this.fishName,
      minPercent: this.minPercent,
      maxPercent: this.maxPercent,
      season: this.season?.toString()
    })
  }

  toWikiTag(): string {
    throw Error("toWikiTag is not implemented")
  }

  fromJSON(json: string): FishSpawn {
    const parsed = JSON.parse(json)

    const fish = new FishSpawn(
      parsed.fishName,
      parsed.weight,
      parsed.minPercent,
      parsed.maxPercent,
      parsed.season === undefined ? undefined : parsed.season as Season
    )

    return fish.copyWith({
      id: parsed.id,
    })
  }
}
