import Dialogue from "@/models/dialogue"
import { CommitFunction, RootState } from "."
import { Commit } from "vuex"

export interface CutsceneModuleState {
  editingText: string,
  dialogues: Dialogue[]
  title: string,
  collapse: string,
  size: string
}

export enum CutsceneActions {
  SetEditingText = "cutscene/setEditingText",
  SetDialogues = "cutscene/setDialogues",
  SetTitle = "cutscene/setTitle",
  SetCollapse = "cutscene/setCollapse",
  SetSize = "cutscene/setSize",
  UpdateDialogue = "cutscene/setDialogue",
  Reset = "cutscene/reset",
  ParseInput = "cutscene/parseRawText"
}

export const cutscene = {
  namespaced: true,
  state: (): CutsceneModuleState => ({
    editingText: '',
    dialogues: [] as Dialogue[],
    title: '',
    collapse: 'Blank',
    size: 'Medium'
  }),
  actions: {
    reset({ commit }: { commit: Commit }) {
      commit('setEditingText', "")
      commit('setDialogues', [])
      commit('setTitle', "")
      commit('setCollapse', "True")
      commit('setSize', "Medium")
    },
    /**
     *  This handles two different styles of dialogue text.
     * 
     * The first is pure text: 
     * Hmm... 
     * > Elder Gorwin says Nivara is ready to train me. May I pass?
     * > Hello?
     * > Would you just get out of my way?
     * I can let... no one see Nivara...
     * 
     * The second has some text for describing options and responses:
     * |Dialogue=Sorry if I seem a little off. My aura is pinker than usual today.
     * |Option1=Are you seeing anybody? <span style="color: #710193;">'''Quest: [[CatherineHangout1Quest]]'''</span>
     * |Response1=Oh no, nothing romantic like that...
     **/
    parseRawText({ commit, state, rootState }: { commit: Commit, state: CutsceneModuleState, rootState: RootState }) {
      const tmp = state.editingText.split("\n").filter((line: string) => line.trim() !== "")
      const result = []

      let speaker = null;
      for (const line of tmp) {
        const matchingNpc = rootState.npcs.find((npc: string) => npc.toLowerCase() == line.toLowerCase())

        // It seems like the wiki should be able to handle this kind of text, so we don't have to edit it?
        // if (line.match(Dialogue.DialogueDescriptionRegex) !== null) {
        //   const cleanedLine = line.replace(Dialogue.DialogueDescriptionRegex, "")
        //   if (line.includes("Option")) {
        //     result.push(new Dialogue("Player", cleanedLine))
        //   } else if (line.includes("Dialogue")) {
        //     result.push(new Dialogue('', cleanedLine))
        //   } else {
        //     result.push(new Dialogue('', cleanedLine, 2))
        //   }
        // } else 
        
        if (line.startsWith("> ")) {
          result.push(new Dialogue("Player", line))
        } else if (matchingNpc != undefined) {
          speaker = matchingNpc;
        } else if (speaker != null && speaker != undefined) {
          result.push(new Dialogue(speaker, line))
        } else {
          result.push(new Dialogue("", line))
        }
      }

      for (let i = 1; i < result.length; i++) {
        if (result[i].speaker == 'Player' && result[i - 1].speaker == 'Player') {
          result[i].indent = 1;
          result[i - 1].indent = 1;
        }

        // const heartMatch = result[i].line.match(Dialogue.HeartChangeRegex)
        // if (heartMatch !== null) {
        //   result[i].heartChange = Number.parseInt(heartMatch[0].match(new RegExp(/-?\d+/g))![0])
        // }
      }

      commit('setDialogues', result)
    }
  },
  mutations: {
    setEditingText (state: CutsceneModuleState, payload: string) {
      state.editingText = payload
    },
    setDialogues (state: CutsceneModuleState, payload: Dialogue[]) {
      state.dialogues = payload
    },
    setDialogue (state: CutsceneModuleState, payload: Dialogue) {
      const index = state.dialogues.findIndex((item: Dialogue) => item.id === payload.id);
      state.dialogues[index] = payload
    },
    setTitle (state: CutsceneModuleState, payload: string) {
      state.title = payload
    },
    setCollapse (state: CutsceneModuleState, payload: string) { 
      state.collapse = payload
    },
    setSize (state: CutsceneModuleState, payload: string) {
      state.size = payload
    }
  },
  getters: {
    rawText (state: CutsceneModuleState): string {
      return state.editingText
    },
    outputText (state: CutsceneModuleState): string {
      const result = []

      if (state.title !== '') {
        result.push(`{{Event Scene/header|${state.title}|Collapse=${state.collapse}|Size=${state.size}}}`)
      } else {
        result.push(`{{Event Scene/header|Collapse=${state.collapse}|Size=${state.size}}}`)
      }

      for (const dialogue of state.dialogues) {
        let race = ""
        if (dialogue.race !== '' && dialogue.race !== undefined && dialogue.race !== null) {
          race = `{{Race|${dialogue.race}}}`
        }

        let hearts = ""
        if (dialogue.heartChange !== 0) {
          hearts = `{{Heart Points|${dialogue.heartChange > 0 ? '+' : '-'}|${Math.abs(dialogue.heartChange)}}}`
        }

        if (!dialogue.ignored) {
          if (dialogue.speaker === '' || dialogue.speaker === null) {
            result.push(`{{Event Scene|action=${dialogue.cleanLine}}}`)
          } else {
            result.push(`{{Event Scene|indent=${dialogue.indent}|${dialogue.speaker}|${race}${dialogue.cleanLine}${hearts}}}`)
          }
        }
      }

      result.push("{{Event Scene/footer}}")

      return result.join("\n")
    }
  }
}

