import { RootState } from "."
import { Commit } from "vuex"
import Enemy from "@/models/enemy"
import FishSpawn from "@/models/fish_spawn"

export interface FishSpawnModuleState {
  editingText: string,
  spawns: FishSpawn[],
}

export enum FishSpawnActions {
  SetEditingText = "fishSpawnerModule/setEditingText",
  SetSpawns = "fishSpawnerModule/setSpawns",
  Reset = "fishSpawnerModule/reset",
  ParseInput = "fishSpawnerModule/parseRawText",
}

export const fishSpawnerModule = {
  namespaced: true,
  state: (): FishSpawnModuleState => ({
    editingText: '',
    spawns: [] as FishSpawn[],
  }),
  actions: {
    reset({ commit }: { commit: Commit }) {
      commit('setEditingText', "")
      commit('setSpawns', [])
    },
    /**
     * This handles the following format:
     * 
     * :Details
     * - Level: 24.0
     * - Health: 210.0
     * - Exp: 38.0
     * :Drops
     * - 1 Dragonfly @ 5.0%
     * - 2 Dragonfly @ 3.0%
     * - 45-60 Coins @ 4.0%
     * - 1 Dorado @ 5.0%
     * - 1 Iron Legs @ 10.0%
     * - 1 Iron Helmet @ 10.0%
     * - 1 Iron Chest Plate @ 10.0%
     * - 1 Iron Gloves @ 10.0%
     **/
    parseRawText({ commit, state }: { commit: Commit, state: FishSpawnModuleState }) {
      const tmp = state.editingText.split("\n").filter((line: string) => line.trim() != "")
      const parsedSpawns = []

      enum ParseState {
        Unknown,
        FishSpawns,
      }

      let parseState = ParseState.Unknown
      let i = 0

      while (i < tmp.length) {
        const line = tmp[i]

        // Check if we are on a declaration line
        if (['Any', 'Spring', 'Summer', 'Fall', 'Winter'].find((season) => line.startsWith(season)) === undefined) {
          parseState = ParseState.Unknown
        } else {
          parseState = ParseState.FishSpawns
        }

        if (parseState === ParseState.FishSpawns) {
          // Wrapping this in a try because this is brittle and heavily reliant on predictable input
          try {
            // Pieces will probably look like ["Any:", "Bonemouth Bass", "@", "10.0", "min%=6.86", "max%=20.5"]
            const pieces = line.split(RegExp(/\s/g)).filter((i) => i != "") as string[]
  
            let season;
            if (!pieces[0].includes("Any")) {
              season = pieces[0].trim().replace(":", "") as Season
            }

            const endNameIndex = pieces.findIndex((piece) => piece === "@")

            const minPiece = pieces.find((piece) => piece.includes('min%'))
            const maxPiece = pieces.find((piece) => piece.includes('max%'))
  
            parsedSpawns.push(new FishSpawn(
              pieces.slice(1, endNameIndex).join(" ").trim(),
              1,
              Number.parseFloat(minPiece!.replace("min%=", "").replace("%", "")),
              Number.parseFloat(maxPiece!.replace("max%=", "").replace("%", "")),
              season
            ))
          } catch {
            // pass
          }
        }

        i++
      }

      commit('setSpawns', parsedSpawns)
    }
  },
  mutations: {
    setEditingText (state: FishSpawnModuleState, payload: string) {
      state.editingText = payload
    },
    setSpawns (state: FishSpawnModuleState, payload: FishSpawn[]) {
      state.spawns = payload
    },
  },
  getters: {
    fishSpawnsMinTable (state: FishSpawnModuleState, getters: any): string {
      let result = []

      result.push('{| class="wikitable" style="text-align:center;"')
      
      console.log(getters.fishSpawnBodyMinPercent)
      result = result.concat(getters.fishSpawnBodyMinPercent)

      result.push("|}")

      return result.join("\n")
    },
    fishSpawnsMaxTable (state: FishSpawnModuleState, getters: any): string {
      let result = []

      result.push('{| class="wikitable" style="text-align:center;"')
      
      console.log(getters.fishSpawnBodyMaxPercent)
      result = result.concat(getters.fishSpawnBodyMaxPercent)

      result.push("|}")

      return result.join("\n")
    },
    fishSpawnBodyMaxPercent (state: FishSpawnModuleState): string[] {
      const result = []

      result.push('! Fish !! Chance')
      
      for (const drop of state.spawns) {
        result.push('|-')

        result.push(`| {{i|${drop.fishName}}} || ${drop.minPercent?.toFixed(2)}%`)
      }

      return result
    },
    fishSpawnBodyMinPercent (state: FishSpawnModuleState): string[] {
      const result = []

      result.push('! Fish !! Chance')
      
      for (const drop of state.spawns) {
        result.push('|-')

        result.push(`| {{i|${drop.fishName}}} || ${drop.maxPercent?.toFixed(2)}%`)
      }

      return result
    }
  }
}

