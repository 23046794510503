<template>
  <select 
    :value="modelValue"
    @input="updateValue"
    v-bind="$attrs"
    class="p-2 
      bg-slate-100 dark:bg-slate-700 
      text-slate-700 dark:text-slate-300
      border border-slate-300 dark:border-slate-700
      rounded-md mb-4
      ">
    <option 
      v-for="option in options" :key="option" :value="option"
      class="p-2 bg-slate-100 dark:bg-slate-700 
      text-slate-700 dark:text-slate-300
      border border-slate-300 dark:border-slate-700 
      ">{{option}}</option>
  </select>
</template>

<script>
export default {
  props: {
    options: Array,
    modelValue: String
  },
  setup(props, context) {
    const updateValue = (event) => {
      context.emit('update:modelValue', event.target.value);
    }

    return { updateValue }
  }
}
</script>

<style>

</style>