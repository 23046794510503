import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CutsceneHelper from '../views/CutsceneHelper.vue'
import QuestRewardHelper from '../views/QuestRewardHelper.vue'
import DropsHelper from '../views/DropsHelper.vue'
import FishSpawnHelper from '../views/FishSpawnHelper.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/helpers/cutscene',
    name: 'Cutscenes',
    component: CutsceneHelper
  },
  {
    path: '/helpers/quest-rewards',
    name: 'Quest Rewards',
    component: QuestRewardHelper
  }, 
  {
    path: '/helpers/drops',
    name: 'Drop Tables',
    component: DropsHelper
  }, 
  {
    path: '/helpers/fish-spawns',
    name: 'Fish Spawns',
    component: FishSpawnHelper
  }, 
  {
    path: "/:catchAll(.*)",
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
