<template>
  <div class="px-6 mb-2 flex flex-row w-full grow border-b border-slate-500">
    <div :class="{
      'dialogue w-4/5 flex flex-row items-center gap-x-2': true,
      'opacity-40': editingDialogue.ignored,
      'italic': editingDialogue.isAction
      }">
      <div v-for="i in editingDialogue.indent" :key="i">
        <div class="w-8"></div>
      </div>
      <face-image :npcName="editingDialogue.speaker"/>
      <p>{{editingDialogue.cleanLine}}</p>
      </div>

    <div class="grid grid-cols-3 grid-rows-2 w-1/5 min-w-min text-left gap-x-2 items-center">
      <labeled-item addClasses="col-span-2" labelText="Speaker">
        <wiki-helper-select
          :options="npcs"
          v-model="editingDialogue.speaker"
        />
      </labeled-item>

      <labeled-item labelText="Race">
        <wiki-helper-select
          :options="races"
          v-model="editingDialogue.race"
        />
      </labeled-item>

      <div class="row-start-2">
        <labeled-item labelText="Ignore?">
          <input type="checkbox" class="w-6 h-6 bg-purple-100 border-purple-300 text-purple-500 focus:ring-purple-200 rounded" v-model="editingDialogue.ignored"/>
        </labeled-item>
      </div>

      <labeled-item addClasses="row-start-2" labelText="Indent">
        <div class="flex flex-row row-start-2 w-min h-6 border border-gray-500 rounded-sm">
          <button class="h-6 w-6 disabled:opacity-30" @click="removeIndent" :disabled="editingDialogue.indent === 0">
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
          </button>
          <button class="h-6 w-6" @click="addIndent">
            <font-awesome-icon icon="fa-solid fa-indent" />
          </button>
        </div>
      </labeled-item>

      <labeled-item addClasses="row-start-2" labelText="Hearts">
        <input
          class="w-16 px-2 rounded-sm text-slate-600 dark:text-slate-500 bg-slate-200 dark:bg-slate-900" 
          type="number" min="-20" max="20" step="1"
          v-model="editingDialogue.heartChange"/>
      </labeled-item>
    </div>
  </div>

</template>

<script>
import { useStore } from 'vuex'
import { key } from '../../store';
import { ref } from 'vue'
import FaceImage from '../common/FaceImage.vue';
import LabeledItem from '../common/LabeledItem.vue';
import Dialogue from '@/models/dialogue';
import WikiHelperSelect from '../common/WikiHelperSelect.vue';

export default {
  components: { FaceImage, LabeledItem, WikiHelperSelect },
  props: {
    dialogue: Dialogue,
  },
  methods: {
    addIndent() {
      this.editingDialogue.indent += 1;
    },
    removeIndent() {
      this.editingDialogue.indent -= 1;
    }
  },
  setup(props) {
    const store = useStore(key)

    return { 
      editingDialogue: ref(props.dialogue),
      npcs: store.state.npcs,
      races: store.state.races,
    };
  },
  watch: {
    editingDialogue: {
      handler: function(val) {
        console.log("emitting dialogue update")
        this.$emit("dialogueUpdated", val);
      },
      deep: true
    }
  }
}
</script>

<style>
.cutscene-line-container {
  border: 1px solid grey;
  border-radius: 6px;
  margin: 12px;
}

.dialogue-container {
  display: flex;
  justify-content: flex-end;
}

.dialogue {
  text-align: start;
}


.ignored {
  color: lightgrey;
}

label {
  align-self: center;
}

input[type="checkbox"], select {
  cursor: pointer;
}


</style>

