
import { mapGetters, useStore } from 'vuex'
import { key } from '../store';
import { defineComponent } from 'vue'
import ContentContainer from '../components/common/ContentContainer.vue'
import WikiHelperButton from '../components/common/WikiHelperButton.vue'
import LabeledItem from '../components/common/LabeledItem.vue'
import { FishSpawnActions } from '@/store/fish_spawner_module';

export default defineComponent({
  components: { 
    ContentContainer,
    WikiHelperButton, 
    LabeledItem 
  },
  data(): { notifyCopied: boolean } {
    return {
      notifyCopied: false,
    }
  },
  setup () {
    const store = useStore(key)

    return {
      textInput: (store.state as any).fishSpawnerModule.editingText,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    refreshLines() {
      this.$store.commit(FishSpawnActions.SetEditingText.toString(), this.textInput)
      this.$store.dispatch(FishSpawnActions.ParseInput.toString())
    },
    copyOutput (whatToCopy: string) {
      if (whatToCopy == "minPercentTable") {
        navigator.clipboard.writeText(this.minPercentTable)
      } else {
        navigator.clipboard.writeText(this.maxPercentTable)
      }
      this.notifyCopied = true;
      window.setTimeout(() => {
        this.notifyCopied = false;
      }, 4000)
    },
    reset() {
      this.$store.dispatch(FishSpawnActions.Reset.toString());
      this.textInput = '';
      (this.$refs.fishSpawnText as HTMLTextAreaElement).value = '';
    }
  },
  computed: {
    ...mapGetters('fishSpawnerModule', {
      minPercentTable: 'fishSpawnsMinTable',
      maxPercentTable: 'fishSpawnsMaxTable',
    }),
  }
})
