
export const npcImages: Map<string, string> = new Map<string, string>([
  ['Iris', 'https://static.wikia.nocookie.net/sun-haven/images/5/5c/Iris.png'],
['Kitty', 'https://static.wikia.nocookie.net/sun-haven/images/5/59/Kitty.png'],
['Lucia', 'https://static.wikia.nocookie.net/sun-haven/images/3/34/Lucia.png'],
['Vivi', 'https://static.wikia.nocookie.net/sun-haven/images/4/4d/Vivi.png'],
['Xyla', 'https://static.wikia.nocookie.net/sun-haven/images/7/71/Xyla.png'],
['Claude', 'https://static.wikia.nocookie.net/sun-haven/images/0/0b/Claude.png'],
['Darius', 'https://static.wikia.nocookie.net/sun-haven/images/b/bb/Darius.png'],
['Donovan', 'https://static.wikia.nocookie.net/sun-haven/images/a/a3/Donovan.png'],
['Jun', 'https://static.wikia.nocookie.net/sun-haven/images/d/d2/Jun.png'],
['Kai', 'https://static.wikia.nocookie.net/sun-haven/images/2/29/Kai.png'],
['Liam', 'https://static.wikia.nocookie.net/sun-haven/images/1/15/Liam.png'],
['Nathaniel', 'https://static.wikia.nocookie.net/sun-haven/images/9/9c/Nathaniel.png'],
['Vaan', 'https://static.wikia.nocookie.net/sun-haven/images/b/b2/Vaan.png'],
['Wesley', 'https://static.wikia.nocookie.net/sun-haven/images/7/7e/Wesley.png'],
['Wornhardt', 'https://static.wikia.nocookie.net/sun-haven/images/1/19/Wornhardt.png'],
['Albert', 'https://static.wikia.nocookie.net/sun-haven/images/2/25/Albert.png'],
['Allison', 'https://static.wikia.nocookie.net/sun-haven/images/4/4d/Allison.png'],
['Amanda', 'https://static.wikia.nocookie.net/sun-haven/images/2/29/Amanda.png'],
['Anne', 'https://static.wikia.nocookie.net/sun-haven/images/7/70/Anne.png'],
['Arvel', 'https://static.wikia.nocookie.net/sun-haven/images/4/45/Arvel.png'],
['Bernard', 'https://static.wikia.nocookie.net/sun-haven/images/b/b4/Bernard.png'],
['Billie', 'https://static.wikia.nocookie.net/sun-haven/images/8/8a/Billie.png'],
['Calvin', 'https://static.wikia.nocookie.net/sun-haven/images/8/8b/Calvin.png'],
['Camila', 'https://static.wikia.nocookie.net/sun-haven/images/2/21/Camila.png'],
['Catherine', 'https://static.wikia.nocookie.net/sun-haven/images/a/a5/Catherine.png'],
['Cynthia', 'https://static.wikia.nocookie.net/sun-haven/images/5/5c/Cynthia.png'],
['Elizabeth', 'https://static.wikia.nocookie.net/sun-haven/images/d/d9/Elizabeth.png'],
['Emma', 'https://static.wikia.nocookie.net/sun-haven/images/4/46/Emma.png'],
['Emmett', 'https://static.wikia.nocookie.net/sun-haven/images/0/0f/Emmett.png'],
['Eve', 'https://static.wikia.nocookie.net/sun-haven/images/6/64/Eve.png'],
['Giuseppe', 'https://static.wikia.nocookie.net/sun-haven/images/6/6e/Giuseppe.png'],
['Heather', 'https://static.wikia.nocookie.net/sun-haven/images/4/46/Heather.png'],
['Jade', 'https://static.wikia.nocookie.net/sun-haven/images/7/7e/Jade.png'],
['Jordan', 'https://static.wikia.nocookie.net/sun-haven/images/e/e4/Jordan.png'],
['Judith', 'https://static.wikia.nocookie.net/sun-haven/images/1/10/Judith.png'],
['Kara', 'https://static.wikia.nocookie.net/sun-haven/images/5/50/Kara.png'],
['Lester', 'https://static.wikia.nocookie.net/sun-haven/images/8/87/Lester.png'],
['Lynn', 'https://static.wikia.nocookie.net/sun-haven/images/7/75/Lynn.png'],
['Mari', 'https://static.wikia.nocookie.net/sun-haven/images/4/4f/Mari.png'],
['Nim', 'https://static.wikia.nocookie.net/sun-haven/images/f/fd/Nim.png'],
['Peter', 'https://static.wikia.nocookie.net/sun-haven/images/9/91/Peter.png'],
['Pinto', 'https://static.wikia.nocookie.net/sun-haven/images/3/3e/Pinto.png'],
['Pod', 'https://static.wikia.nocookie.net/sun-haven/images/7/76/Pod.png'],
['Raimi', 'https://static.wikia.nocookie.net/sun-haven/images/2/20/Raimi.png'],
['Rex', 'https://static.wikia.nocookie.net/sun-haven/images/5/5f/Rex.png'],
['Ronald', 'https://static.wikia.nocookie.net/sun-haven/images/d/d8/Ronald.png'],
['Roza', 'https://static.wikia.nocookie.net/sun-haven/images/a/ab/Roza.png'],
['Rupert', 'https://static.wikia.nocookie.net/sun-haven/images/f/fc/Rupert.png'],
['Scott', 'https://static.wikia.nocookie.net/sun-haven/images/d/d2/Scott.png'],
['Solon', 'https://static.wikia.nocookie.net/sun-haven/images/0/0f/Solon.png'],
['Sophie', 'https://static.wikia.nocookie.net/sun-haven/images/5/50/Sophie.png'],
['Stephen', 'https://static.wikia.nocookie.net/sun-haven/images/b/bd/Stephen.png'],
['Tony', 'https://static.wikia.nocookie.net/sun-haven/images/9/9a/Tony.png'],
['Topi', 'https://static.wikia.nocookie.net/sun-haven/images/4/46/Topi.png'],
['Altar Keeper', 'https://static.wikia.nocookie.net/sun-haven/images/4/4a/Altar_Keeper.png'],
['Arianella', 'https://static.wikia.nocookie.net/sun-haven/images/4/41/Arianella.png'],
['Arnold', 'https://static.wikia.nocookie.net/sun-haven/images/f/f5/Arnold.png'],
['Batilda', 'https://static.wikia.nocookie.net/sun-haven/images/2/2b/Batilda.png'],
['Batrick', 'https://static.wikia.nocookie.net/sun-haven/images/6/61/Batrick.png'],
['Benny', 'https://static.wikia.nocookie.net/sun-haven/images/2/22/Benny.png'],
['Bloberta', 'https://static.wikia.nocookie.net/sun-haven/images/a/a0/Bloberta.png'],
['Caspera', 'https://static.wikia.nocookie.net/sun-haven/images/c/c7/Caspera.png'],
['Cassia', 'https://static.wikia.nocookie.net/sun-haven/images/e/ee/Cassia.png'],
['Cayde', 'https://static.wikia.nocookie.net/sun-haven/images/e/e6/Cayde.png'],
['Charon', 'https://static.wikia.nocookie.net/sun-haven/images/e/e2/Charon.png'],
['Chauncey', 'https://static.wikia.nocookie.net/sun-haven/images/1/13/Chauncey.png'],
['Chester', 'https://static.wikia.nocookie.net/sun-haven/images/0/06/Chester.png'],
['Christine', 'https://static.wikia.nocookie.net/sun-haven/images/6/6a/Christine.png'],
['Coral', 'https://static.wikia.nocookie.net/sun-haven/images/b/b1/Coral.png'],
['Cordelia', 'https://static.wikia.nocookie.net/sun-haven/images/f/fe/Cordelia.png'],
['Denzel', 'https://static.wikia.nocookie.net/sun-haven/images/7/71/Denzel.png'],
['Dugan', 'https://static.wikia.nocookie.net/sun-haven/images/4/40/Dugan.png'],
['Dwayne', 'https://static.wikia.nocookie.net/sun-haven/images/c/ce/Dwayne.png'],
['Faeyon', 'https://static.wikia.nocookie.net/sun-haven/images/a/a9/Faeyon.png'],
['Felicity', 'https://static.wikia.nocookie.net/sun-haven/images/a/aa/Felicity.png'],
['Felix', 'https://static.wikia.nocookie.net/sun-haven/images/6/6e/Felix.png'],
['Fidget', 'https://static.wikia.nocookie.net/sun-haven/images/8/83/Fidget.png'],
['Flemmett', 'https://static.wikia.nocookie.net/sun-haven/images/2/22/Flemmett.png'],
['Gerald', 'https://static.wikia.nocookie.net/sun-haven/images/6/6c/Gerald.png'],
['Jet', 'https://static.wikia.nocookie.net/sun-haven/images/1/1e/Jet.png'],
['Linus', 'https://static.wikia.nocookie.net/sun-haven/images/3/31/Linus.png'],
['Luis', 'https://static.wikia.nocookie.net/sun-haven/images/d/d0/Luis.png'],
['Honey (NPC)', 'https://static.wikia.nocookie.net/sun-haven/images/f/f6/Honey_%28NPC%29.png'],
['Malachai', 'https://static.wikia.nocookie.net/sun-haven/images/7/78/Malachai.png'],
['Morpha', 'https://static.wikia.nocookie.net/sun-haven/images/1/12/Morpha.png'],
['Phoebe', 'https://static.wikia.nocookie.net/sun-haven/images/3/35/Phoebe.png'],
['Rastus', 'https://static.wikia.nocookie.net/sun-haven/images/1/1f/Rastus.png'],
['Rufus', 'https://static.wikia.nocookie.net/sun-haven/images/f/fc/Rufus.png'],
['Slobert', 'https://static.wikia.nocookie.net/sun-haven/images/8/86/Slobert.png'],
['Sombasu', 'https://static.wikia.nocookie.net/sun-haven/images/b/b9/Face_Sombasu.png'],
['Tali', 'https://static.wikia.nocookie.net/sun-haven/images/3/3f/Tali.png'],
['Ticket Monster', 'https://static.wikia.nocookie.net/sun-haven/images/d/d9/Ticket_Monster.png'],
['Triberius', 'https://static.wikia.nocookie.net/sun-haven/images/4/4b/Triberius.png'],
['Wyatt', 'https://static.wikia.nocookie.net/sun-haven/images/9/92/Wyatt.png'],
['Zeke', 'https://static.wikia.nocookie.net/sun-haven/images/4/42/Zeke.png'],
['Zenos', 'https://static.wikia.nocookie.net/sun-haven/images/a/a5/Zenos.png'],
['Zuri', 'https://static.wikia.nocookie.net/sun-haven/images/f/fe/Zuri.png'],
['Spectral Knight', 'https://static.wikia.nocookie.net/sun-haven/images/a/a9/Spectral_Knight.png'],
['Demon King Minos', 'https://static.wikia.nocookie.net/sun-haven/images/d/d8/Demon_King_Minos.png'],
['Angel', 'https://static.wikia.nocookie.net/sun-haven/images/8/83/Angel.png'],
['Beeanca', 'https://static.wikia.nocookie.net/sun-haven/images/a/a9/Beeanca.png'],
['Caspian', 'https://static.wikia.nocookie.net/sun-haven/images/3/34/Caspian.png'],
['Clay', 'https://static.wikia.nocookie.net/sun-haven/images/a/a2/Clay.png'],
['Clemmett', 'https://static.wikia.nocookie.net/sun-haven/images/d/d2/Clemmett.png'],
['Dew', 'https://static.wikia.nocookie.net/sun-haven/images/e/ee/Dew.png'],
['Fonzo', 'https://static.wikia.nocookie.net/sun-haven/images/2/2b/Fonzo.png'],
['Gorwin', 'https://static.wikia.nocookie.net/sun-haven/images/b/b1/Gorwin.png'],
['Granny', 'https://static.wikia.nocookie.net/sun-haven/images/d/d9/Granny.png'],
['Gren', 'https://static.wikia.nocookie.net/sun-haven/images/7/7a/Gren.png'],
['Jarrod', 'https://static.wikia.nocookie.net/sun-haven/images/b/b0/Jarrod.png'],
['Kimi', 'https://static.wikia.nocookie.net/sun-haven/images/2/21/Kimi.png'],
['Lars', 'https://static.wikia.nocookie.net/sun-haven/images/6/66/Lars.png'],
['Lily (NPC)', 'https://static.wikia.nocookie.net/sun-haven/images/0/0b/Lily_%28NPC%29.png'],
['Lucius', 'https://static.wikia.nocookie.net/sun-haven/images/5/5d/Lucius.png'],
['Luke', 'https://static.wikia.nocookie.net/sun-haven/images/4/4f/Luke.png'],
['Morgan', 'https://static.wikia.nocookie.net/sun-haven/images/b/b5/Morgan.png'],
['Opal', 'https://static.wikia.nocookie.net/sun-haven/images/3/3c/Opal.png'],
['Quincy', 'https://static.wikia.nocookie.net/sun-haven/images/5/56/Quincy.png'],
['Reed', 'https://static.wikia.nocookie.net/sun-haven/images/c/c4/Reed.png'],
['Sable', 'https://static.wikia.nocookie.net/sun-haven/images/a/a8/Sable.png'],
['Sasha', 'https://static.wikia.nocookie.net/sun-haven/images/0/04/Sasha.png'],
['Slate', 'https://static.wikia.nocookie.net/sun-haven/images/9/97/Slate.png'],
['Sylvia', 'https://static.wikia.nocookie.net/sun-haven/images/6/6c/Sylvia.png'],
['Sylvius', 'https://static.wikia.nocookie.net/sun-haven/images/e/e8/Sylvius.png'],
['Rel\'Tar', 'https://static.wikia.nocookie.net/sun-haven/images/d/d1/Rel%27Tar.png'],
['Tornn', 'https://static.wikia.nocookie.net/sun-haven/images/f/f5/Tornn.png'],
['Willow', 'https://static.wikia.nocookie.net/sun-haven/images/b/b2/Willow.png'],
['Nivara', 'https://static.wikia.nocookie.net/sun-haven/images/6/6d/Nivara.png'],    
]);
