
import { mapGetters, mapState, useStore } from 'vuex'
import { key } from '../store';
import { defineComponent } from 'vue'
import CutsceneLine from '../components/cutscene/CutsceneLine.vue'
import ContentContainer from '../components/common/ContentContainer.vue'
import WikiHelperButton from '../components/common/WikiHelperButton.vue'
import WikiHelperSelect from '../components/common/WikiHelperSelect.vue'
import LabeledItem from '../components/common/LabeledItem.vue'
import Dialogue from '../models/dialogue'
import { CutsceneActions } from '@/store/cutscene_module';

interface CutsceneSettings {
  collapseOptions: string[],
  sizeOptions: string[],
  showOutput: boolean,
  notifyCopied: boolean,
  hideIgnored: boolean,
  placeholderSpeaker: ''
}

export default defineComponent({
  components: { 
    CutsceneLine,
    ContentContainer,
    WikiHelperButton, 
    WikiHelperSelect, 
    LabeledItem 
  },
  data(): CutsceneSettings {
    return {
      collapseOptions: ['True', 'False', 'Blank'],
      sizeOptions: ['Medium', 'Large', 'Blank'],
      showOutput: false,
      notifyCopied: false,
      hideIgnored: false,
      placeholderSpeaker: ''
    }
  },
  setup () {
    const store = useStore(key)

    return { 
      npcs: store.state.npcs,
      textInput: (store.state as any).cutscene.editingText,
      title: (store.state as any).cutscene.title,
      collapse: (store.state as any).cutscene.collapse,
      size: (store.state as any).cutscene.size,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    refreshLines() {
      this.$store.commit(CutsceneActions.SetEditingText.toString(), this.textInput)
      this.$store.dispatch(CutsceneActions.ParseInput.toString())
    },
    updateDialogue(dialogue: Dialogue) {
      this.$store.commit(CutsceneActions.UpdateDialogue.toString(), dialogue)
    },
    updateTitle() {
      this.$store.commit(CutsceneActions.SetTitle.toString(), this.title)
    },
    updateCollapse() {
      this.$store.commit(CutsceneActions.SetCollapse.toString(), this.collapse)
    },
    updateSize() {
      this.$store.commit(CutsceneActions.SetSize.toString(), this.size)
    },
    updatePlaceholderSpeaker() {
      const tmp = this.dialogues

      for (const dialogue of tmp) {
        if (dialogue.speaker === '' || dialogue.speaker === null) {
          dialogue.speaker = this.placeholderSpeaker
        }
      }

      this.$store.commit(CutsceneActions.SetDialogues.toString(), tmp)
    },
    arrayMove(arr: Array<Dialogue|undefined>, old_index: number, new_index: number) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    },
    moveLineUp(lineIndex: number) {
      const tmp = this.dialogues

      this.arrayMove(tmp, lineIndex, lineIndex - 1)
      this.$store.commit(CutsceneActions.SetDialogues.toString(), tmp)
    },
    moveLineDown(lineIndex: number) {
      const tmp = this.dialogues

      this.arrayMove(tmp, lineIndex, lineIndex + 1)
      this.$store.commit(CutsceneActions.SetDialogues.toString(), tmp)
    },
    copyOutput () {
      navigator.clipboard.writeText(this.output);
      this.notifyCopied = true;
      window.setTimeout(() => {
        this.notifyCopied = false;
      }, 4000)
    },
    reset() {
      this.$store.dispatch(CutsceneActions.Reset.toString());
      this.textInput = '';
      this.title = '';
      this.collapse = 'True';
      this.size = 'Medium';
      (this.$refs.cutscenetext as HTMLTextAreaElement).value = '';
      (this.$refs.cutsceneTitle as HTMLInputElement).value = '';
    }
  },
  computed: {
    ...mapState('cutscene', {
      dialogues: 'dialogues'
    }),
    ...mapGetters('cutscene', {
      output: 'outputText',
    }),
  }
})
