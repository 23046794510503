import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.npcImageSource !== undefined)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "h-6 w-6",
        src: $setup.npcImageSource
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}