import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "app bg-slate-300 dark:bg-slate-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: "mr-3 font-bold hover:border-b-purple-600 hover:border-b-2",
          key: route,
          to: route.path
        }, {
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(route.name) + " ", 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    _createVNode(_component_router_view)
  ]))
}